

* {
  margin: 0;
  padding: 0;
}


.navMonitor{
  background-color: rgb(244, 244, 244);
}

.navMonitor li{
  text-decoration: none;
}

.navMonitor a {
  color: black;
}



.navigationMonitor{
  background: rgb(27, 31, 44);
  display: flex;
  height: 60px;
}

.navigationMonitor .left{
  width: 30%;
  margin: auto;
  padding-left: 10px;
}

.navigationMonitor .right{
  width: 70%;
  margin: auto;
  text-align: right;
  padding-right: 10px;
}


.btn-recibir{
  background-color: rgb(0, 143, 226);
  color:aliceblue;
  font-size: larger;
  text-decoration: none;

}

.btn-entrega{
  background-color: rgba(229, 70, 2, 0.683);
  color:aliceblue;
  font-size:larger;
  text-decoration: none;
}

.btn-submenu{
  padding: 5px;
  margin: 5px;
  border-radius: 0px !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

.btn-id{
  background-color: #198754;
  color: white;
  font-size: 13px;
  font-weight:bold;
}


.btn-submenuSuccess{
  padding: 5px;
  margin: 5px;
  border-radius: 4px !important;
  background-color: rgb(26, 124, 27);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  color: white;
}


input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  color: #333;
  outline: none;
}

input[type="text"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.w-100{
  width: 100%;
}


.qr{
  background-image: url('./img/qr.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.navSubmenu{
  background: #e6e6e6;
  border-top: 1px solid rgb(215, 215, 215);
}


table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 5px;
}

th {
  background-color:rgb(27, 31, 44);
  color: #f0f0f0;
  font-weight:300;
  text-transform: uppercase;
  padding: 10px;
  text-align: left;
}

td {
  padding: 10px;
  background-color: #e6e6e6;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}



.seleccionado td{
  transition: all .3s ease;
  color: rgb(252, 252, 252);
  background-color: #b9b9b9;
  border-bottom: 1px solid rgb(178, 183, 199);
  font-size: 13px;
}


.noSeleccionado td{
  transition: all .3s ease;
  font-size: 13px;
}

#html5-qrcode-anchor-scan-type-change{
  visibility: hidden;
}

.btn-nav{
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 20px;
}

.table-list tr td{
  background: #ececec !important;
  padding: 3px;
  padding-left: 10px;
}

.table-list tr td:nth-child(1){
  width: 30%;
  text-transform: uppercase;
  font-size: 14px;
}

.table-list tr td:nth-child(2){
  background-color: #fefefe !important;
  font-size: 14px;
}

#html5-qrcode-button-camera-permission {
  text-transform: uppercase;
  color: #ccc;
  background: rgb(27, 31, 44);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  padding: 10px;

}

#html5-qrcode-button-camera-start {
  background: rgb(0, 132, 5);
}

#html5-qrcode-button-camera-stop {
  background: rgb(197, 0, 0);
}

#html5-qrcode-button-torch {
  background: rgb(42, 42, 42);
}

#html5-qrcode-button-camera-stop, #html5-qrcode-button-camera-start, #html5-qrcode-button-torch {
  appearance: none;
      border: 1px solid rgba(27, 31, 35, 0.15);
      border-radius: 6px;
      box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
      box-sizing: border-box;
      color: #FAFBFC;
      cursor: pointer;
      display: inline-block;
      font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      list-style: none;
      padding: 6px 16px;
      position: relative;
      transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: middle;
      white-space: nowrap;
      word-wrap: break-word;
      margin-bottom: 5px;
}

#html5-qrcode-select-camera {
  margin-bottom: 10px;
  margin-top:10px;
}

.infoDisp{
  background: #202231;
  height: 100vh;
  padding: 30px;
  padding-top: 80px;
}

.infoTitle{
  text-align: justify;
  font-size: 14px;
  color:white;
  padding: 10px;
  border-radius: 10px;
  background: brown;
}

.buttonS{
  background: rgb(50, 115, 227);
  color: white;
  padding: 10px;
  border-radius: 10px;
}


.btn-red{
  color:white;
  border-radius: 5px;
  padding: 5px;
  border: none;
  background: brown;
}

/* #html5-qrcode-button-camera-permission::after{
  content: 'Autorizar camara';
  visibility: visible;
  text-transform: uppercase;
  color: #ccc;
  display: flex ;
  background: rgb(27, 31, 44);
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  padding: 10px;

} */


.Toastify__toast-container{
  width: 80% !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}


.custom-select {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.custom-select:focus {
  border-color: #d5d6d7;
  background: #ffffff;
  outline: 0;
}



.custom-select::-ms-expand {
  display: none;
  background: #ffffff;
}

.custom-select[disabled], .custom-select[readonly], fieldset[disabled] .custom-select {
  background-color: #eee;
  opacity: 1;
}

.custom-select[disabled], fieldset[disabled] .custom-select {
  cursor: not-allowed;
}

.custom-select .select-arrow {
  position: absolute;
  background: #ffffff;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #888;
}


.delete{
  position: absolute;
  right: 10px;
  background: rgb(253, 58, 58);
  padding: 10px;
  border: none;
  border-radius: 5px;
  justify-content: center;
}

.delete i{
  color: white;
}

.take-photo{
  width: 100%;
  padding: 30px;
}



.btn-disp{
  padding: 4px;
  width: 100%;
  height: 40px;
  border: 3px solid rgb(220, 220, 220);
  border-radius: 5px;
  margin-top: 20px;
}



.disp{
  display: block;
}

.disp2{
  display: none;
}

.btn-archivo-foto{
  padding: 5px;
  background: #e1e1e1;
  border: 3px solid rgb(220, 220, 220);
  border-radius: 5px;
  width: 100%;
}

.btn-submit{
  background: #007bff;
  color: white;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  height: 40px;
  border: none;
}

.icons{
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* padding: 0px !important; */
  padding: 10px;
}


.imgmini{
  position: relative;
  margin-top: 20px;
  height: 40px;
  width: 38px;
}

.btn-photo{
  background: #e1e1e1;
  color: black;
  font-size: 20px;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  /* overflow: hidden; */
  border: none;
  /* position: relative; */
}


.camera{
  position: absolute;
  background: rgb(216, 216, 216);
  width: 100%;
  border-radius: 5px;
  left: 0;
  top: 0;
  height: auto;
  padding: 10px;
  z-index: 100;
}

.foto{
  position: relative;
  width: 100%;
  height:  300px;
  margin: auto;
  background: white;
}


.foto2{  
  position: relative;
  width: 100%;
  max-height: 300px;
  margin: auto;
  overflow: hidden;
  background: white;
}

.foto2 img{
  width: 100%;
  align-content: center;
}

.botones{
  position: relative;
  width: 100%;
}

.video{
  height: 100%;
  width: 100%;
  position: relative;
}

.codigoEntrega{
  width: 100%;
  display: inline;
  justify-content: center;
  text-align: center;
}

.qrEntregaNro{
  font-size: 28px;
  font-weight: 600;
}
